(function () {
    $.reject({
        reject: {
            // all: true
            msie: 10,
        }, // Reject all renderers for demo
        imagePath: '/assets/img/browsers/',
        close: false, // Prevent closing of window  
        header: 'Please upgrade your browser to use Worldfavor',
        paragraph1:
			'Security and integrity is a key compontent in Worldfavor. To ensure that your data is protected we currently do not support your browser. Download one of these great browsers and you will be on your way:',
        paragraph2: '&nbsp;',
        afterReject() {
            window.wf__isOldBrowser = true
        },
        overlayBgColor: '#F7F7F3',
        // Background transparency (0-1)
        overlayOpacity: 1,
    })
})()
