const validUploadFileTypesSpec = [
    {
        fileType: 'pdf',
        mimeType: 'application/pdf',
    },
    {
        fileType: 'xlsx',
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    {
        fileType: 'jpg',
        mimeType: 'image/jpeg',
    },
    {
        fileType: 'docx',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    },
    {
        fileType: 'png',
        mimeType: 'image/png',
    },
    {
        fileType: 'doc',
        mimeType: 'application/msword',
    },
    {
        fileType: 'msg',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'pptx',
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    },
    {
        fileType: 'xls',
        mimeType: 'application/vnd.ms-excel',
    },
    {
        fileType: 'csv',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'txt',
        mimeType: 'text/plain',
    },
    {
        fileType: 'jpeg',
        mimeType: 'image/jpeg',
    },
    {
        fileType: 'zip',
        mimeType: 'application/x-zip-compressed',
    },
    {
        fileType: 'rar',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'xlsm',
        mimeType: 'application/vnd.ms-excel.sheet.macroEnabled.12',
    },
    {
        fileType: 'xlsb',
        mimeType: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
    },
    {
        fileType: 'bmp',
        mimeType: 'image/bmp',
    },
    {
        fileType: 'edoc',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'asice',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'eml',
        mimeType: 'message/rfc822',
    },
    {
        fileType: 'odt',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'ppt',
        mimeType: 'application/vnd.ms-powerpoint',
    },
    {
        fileType: 'tif',
        mimeType: 'image/tiff',
    },
    {
        fileType: 'jfif',
        mimeType: 'image/pjpeg',
    },
    {
        fileType: 'p7m',
        mimeType: 'application/pkcs7-mime',
    },
    {
        fileType: 'rtf',
        mimeType: 'application/rtf',
    },
    {
        fileType: '7z',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'pub',
        mimeType: 'application/x-mspublisher',
    },
    {
        fileType: 'dot',
        mimeType: 'application/msword',
    },
    {
        fileType: 'wps',
        mimeType: 'application/vnd.ms-works',
    },
    {
        fileType: 'docm',
        mimeType: 'application/vnd.ms-word.document.macroEnabled.12',
    },
    {
        fileType: 'gif',
        mimeType: 'image/gif',
    },
    {
        fileType: 'mp4',
        mimeType: 'video/mp4',
    },
    {
        fileType: 'numbers',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'gsheet',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'heic',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'pages',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'ods',
        mimeType: 'application/oleobject',
    },
    {
        fileType: 'mp3',
        mimeType: 'audio/mpeg',
    },
    {
        fileType: 'pptm',
        mimeType: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
    },
    {
        fileType: 'xltx',
        mimeType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    },
    {
        fileType: 'dotx',
        mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    },
    {
        fileType: 'webp',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'potx',
        mimeType: 'application/vnd.openxmlformats-officedocument.presentationml.template',
    },
    {
        fileType: 'mov',
        mimeType: 'video/quicktime',
    },
    {
        fileType: 'xlt',
        mimeType: 'application/vnd.ms-excel',
    },
    {
        fileType: 'ico',
        mimeType: 'image/x-icon',
    },
    {
        fileType: 'pfx',
        mimeType: 'application/x-pkcs12',
    },
    {
        fileType: 'ics',
        mimeType: 'text/calendar',
    },
    {
        fileType: 'pot',
        mimeType: 'application/vnd.ms-powerpoint',
    },
    {
        fileType: 'oxps',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'ddoc',
        mimeType: 'application/octet-stream',
    },
    {
        fileType: 'svg',
        mimeType: 'image/svg+xml',
    },
]

// Can be used with the 'accept' attribute of an input element of type 'file'
export function getValidFileTypesString() {
    return validUploadFileTypesSpec.map(x => `.${x.fileType}`).join(',')
}
