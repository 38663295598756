// angular.module("schemaForm").run(["$templateCache", function($templateCache) {$templateCache.put("directives/decorators/bootstrap/datepicker/datepicker.html","<div class=\"form-group {{form.htmlClass}}\" ng-class=\"{\'has-error\': hasError()}\">\n  <label class=\"control-label\" ng-show=\"showTitle()\">{{form.title}}</label>\n  <div ng-class=\"{\'input-group\': (form.fieldAddonLeft || form.fieldAddonRight)}\">\n    <span ng-if=\"form.fieldAddonLeft\"\n          class=\"input-group-addon\"\n          ng-bind-html=\"form.fieldAddonLeft\"></span>\n    <input ng-show=\"form.key\"\n           style=\"background-color: white\"\n           type=\"text\"\n           class=\"form-control {{form.fieldHtmlClass}}\"\n           schema-validate=\"form\"\n           ng-model=\"$$value$$\"\n           ng-disabled=\"form.readonly\"\n           pick-a-date=\"form.pickadate\"\n           min-date=\"form.minDate\"\n           max-date=\"form.maxDate\"\n           name=\"{{form.key.slice(-1)[0]}}\"\n           format=\"form.format\" />\n    <span ng-if=\"form.fieldAddonRight\"\n          class=\"input-group-addon\"\n          ng-bind-html=\"form.fieldAddonRight\"></span>\n  </div>\n  <span class=\"help-block\">{{ (hasError() && errorMessage(schemaError())) || form.description}}</span>\n</div>\n");}]);
angular.module('schemaForm').directive('pickADate', () => {

    //String dates for min and max is not supported
    //https://github.com/amsul/pickadate.js/issues/439
    //So strings we create dates from
    const formatDate = function(value) {
    //Strings or timestamps we make a date of
        if (angular.isString(value) || angular.isNumber(value)) {
            return new Date(value)
        }
        return value //We hope it's a date object
    }

    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel: '=',
            pickADate: '=',
            minDate: '=',
            maxDate: '=',
            format: '=',
        },
        link(scope, element, attrs, ngModel) {
            let clearer

            //Bail out gracefully if pickadate is not loaded.
            if (!element.pickadate) {
                return
            }

            //By setting formatSubmit to null we inhibit the
            //hidden field that pickadate likes to create.
            //We use ngModel formatters instead to format the value.
            const opts = {
                onClose() {
                    element.blur()
                },
                formatSubmit: null,
                onSet(val) {
                    if (val.hasOwnProperty('clear')) {
                        // console.log("hide");
                        clearer.hide()
                    }
                    else {
                        // console.log("show");
                        clearer.show()
                    }

                },
                onOpen: () => {
                    picker.$root.hide()
                    setTimeout(() => {
                        picker.$root.show()
                    }, 100)
                },
            }
            if (scope.pickADate) {
                angular.extend(opts, scope.pickADate)
            }
            opts.selectYears = 200
            opts.selectMonths = true
            element.pickadate(opts)

            //Defaultformat is for json schema date-time is ISO8601
            //i.e.  "yyyy-mm-dd"
            const defaultFormat = 'yyyy-mm-dd'

            //View format on the other hand we get from the pickadate translation file
            const viewFormat    = $.fn.pickadate.defaults.format

            var picker = element.pickadate('picker')

            scope.clear = function () {
                console.log(picker)
                picker.clear()
            }

            //The view value
            ngModel.$formatters.push((value) => {
                if (angular.isUndefined(value) || value === null) {
                    return value
                }

                //We set 'view' and 'highlight' instead of 'select'
                //since the latter also changes the input, which we do not want.
                picker.set('view', value, { format: scope.format || defaultFormat })
                picker.set('highlight', value, { format: scope.format || defaultFormat })

                //piggy back on highlight to and let pickadate do the transformation.
                return picker.get('highlight', viewFormat)
            })

            ngModel.$parsers.push(() => {
                return picker.get('select', scope.format || defaultFormat)
            })

            //bind once.
            if (angular.isDefined(attrs.minDate)) {
                var onceMin = scope.$watch('minDate', (value) => {
                    if (value) {
                        picker.set('min', formatDate(value))
                        onceMin()
                    }
                }, true)
            }

            if (angular.isDefined(attrs.maxDate)) {
                var onceMax = scope.$watch('maxDate', (value) => {
                    if (value) {
                        picker.set('max', formatDate(value))
                        onceMax()
                    }
                }, true)
            }

            clearer = $('<div class="input-group-addon" style="padding-top:0;padding-bottom:0">'
       + '<div style="cursor:pointer;display:none;width:24px;height:28px;color:#888;font-size:16px;text-align: center;float: right;"><i class="fas fa-times" style="line-height: 28px;"></i><div>'
       + '</div>')

            clearer.appendTo(element.parent())

            clearer = clearer.children().first()
            clearer.click(() => {
                picker.clear()
            })

            if (ngModel.$viewValue) clearer.show()

        },
    }
})

angular.module('schemaForm').config(
    ['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
        function(schemaFormProvider,  schemaFormDecoratorsProvider, sfPathProvider) {

            const datepicker = function(name, schema, options) {
                if (schema.type === 'string' && (schema.format === 'date' || schema.format === 'date-time')) {
                    const f = schemaFormProvider.stdFormObj(name, schema, options)
                    f.key  = options.path
                    f.type = 'datepicker'
                    options.lookup[sfPathProvider.stringify(options.path)] = f
                    return f
                }
            }

            schemaFormProvider.defaults.string.unshift(datepicker)

            //Add to the bootstrap directive
            schemaFormDecoratorsProvider.addMapping(
                'bootstrapDecorator',
                'datepicker',
                'scripts/angular-schema-form-datepicker/datepicker.html',
            )
            schemaFormDecoratorsProvider.createDirective(
                'datepicker',
                'scripts/angular-schema-form-datepicker/datepicker.html',
            )
        },
    ])
