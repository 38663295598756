import { getValidFileTypesString } from '@worldfavor/portal/scripts/file-upload/validUploadFileTypes'

(function () {
    'use strict'

    angular
        .module('schemaForm')
        .config(['schemaFormProvider', 'schemaFormDecoratorsProvider', 'sfPathProvider',
            function (schemaFormProvider, schemaFormDecoratorsProvider, sfPathProvider) {
                const defaultPatternMsg  = 'Wrong file type. Allowed types are '
					
                const defaultMaxSizeMsg1 = 'This file is too large. Maximum size allowed is '
					
                const defaultMaxSizeMsg2 = 'Current file size:'
					
                const defaultMinItemsMsg = 'You have to upload at least one file'
					
                const defaultMaxItemsMsg = 'You can\'t upload more than one file.'

                const nwpSinglefileUpload = function (name, schema, options) {
                    if (schema.type === 'array' && schema.format === 'singlefile') {
                        if (schema.pattern && schema.pattern.mimeType && !schema.pattern.validationMessage) {
                            schema.pattern.validationMessage = defaultPatternMsg
                        }
                        if (schema.maxSize && schema.maxSize.maximum && !schema.maxSize.validationMessage) {
                            schema.maxSize.validationMessage  = defaultMaxSizeMsg1
                            schema.maxSize.validationMessage2 = defaultMaxSizeMsg2
                        }
                        if (schema.minItems && schema.minItems.minimum && !schema.minItems.validationMessage) {
                            schema.minItems.validationMessage = defaultMinItemsMsg
                        }
                        if (schema.maxItems && schema.maxItems.maximum && !schema.maxItems.validationMessage) {
                            schema.maxItems.validationMessage = defaultMaxItemsMsg
                        }

                        const f = schemaFormProvider.stdFormObj(name, schema, options)
                        f.key = options.path
                        f.type = 'nwpFileUpload'
                        options.lookup[sfPathProvider.stringify(options.path)] = f

                        return f
                    }
                }

                schemaFormProvider.defaults.array.unshift(nwpSinglefileUpload)

                const nwpMultifileUpload = function (name, schema, options) {
                    if (schema.type === 'array' && schema.format === 'multifile') {
                        if (schema.pattern && schema.pattern.mimeType && !schema.pattern.validationMessage) {
                            schema.pattern.validationMessage = defaultPatternMsg
                        }
                        if (schema.maxSize && schema.maxSize.maximum && !schema.maxSize.validationMessage) {
                            schema.maxSize.validationMessage  = defaultMaxSizeMsg1
                            schema.maxSize.validationMessage2 = defaultMaxSizeMsg2
                        }
                        if (schema.minItems && schema.minItems.minimum && !schema.minItems.validationMessage) {
                            schema.minItems.validationMessage = defaultMinItemsMsg
                        }
                        if (schema.maxItems && schema.maxItems.maximum && !schema.maxItems.validationMessage) {
                            schema.maxItems.validationMessage = defaultMaxItemsMsg
                        }

                        const f = schemaFormProvider.stdFormObj(name, schema, options)
                        f.key = options.path
                        f.type = 'nwpFileUpload'
                        options.lookup[sfPathProvider.stringify(options.path)] = f

                        return f
                    }
                }

                schemaFormProvider.defaults.array.unshift(nwpMultifileUpload)

                schemaFormDecoratorsProvider.addMapping(
                    'bootstrapDecorator',
                    'nwpFileUpload',
                    'scripts/file-upload/nwp-file.html',
                )
            },
        ])

    angular
        .module('ngSchemaFormFile', ['ngFileUpload', 'ngMessages'])
        .directive('ngSchemaFile', ngSchemaFile)

    ngSchemaFile.$inject = ['Upload', '$timeout', '$translate', 'wfAuth']
    function ngSchemaFile(Upload, $timeout, $translate, wfAuth) {
        const directive = {
            restrict: 'A',
            scope: true,
            require: 'ngModel',
            link,
        }

        return directive

        function link(scope, element, attrs, ngModel) {
            const uploadType = scope.form.uploadType
            const ngFormElement = $(element)

            _.assign(scope, {
                //Variables
                url: scope.form && scope.form.endpoint,
                headers: scope.form ? scope.form.headers : undefined,
                isSinglefileUpload: _.get(scope, 'form.schema.format') === 'singlefile',
                isMultifileUpload: _.get(scope, 'form.schema.format') === 'multifile',

                // The currentImageUrl property is both in scope.form and in scope.form.schema['x-schema-form']
                // with identical values the first time the form is initialized.
                // If the form gets reset with a new model but same schema/form, the currentImageUrl directly on
                // scope.form will still have its old value but the value in scope.form.schema['x-schema-form']
                // will be the new one. The values in x-schema-form probably gets copied into the scope.form object
                // only one time - when the form is initialized. Previously the properties here were accessed from scope.form
                // and have now been changed to scope.form.schema['x-schema-form'].
                currentImageUrl: _.get(scope, 'form.schema[\'x-schema-form\'].currentImageUrl'),
                currentFileUrl: _.get(scope, 'form.schema[\'x-schema-form\'].currentFileUrl'),
                authHeaderValue: _.get(scope, 'form.schema[\'x-schema-form\'].authHeaderValue'),

                showDropBox: true,
                fileAlreadyExists: false,
                imageUpload: false,
                unsupportedFileTypes: false,
                currentDropBox: {},
                formLabel: $translate.instant('modules.upload.formLabel'),
                changeButtonText: $translate.instant('Change'),
                changeButtonIcon: 'fas fa-plus',
                dropBoxes: defineDropBoxes().default,
                model: scope.form.model,

                //Functions
                selectFile,
                selectFiles,
                uploadFile,
                showUploader,
                hideUploader,
                uploadFiles,
                validateField,
                submit,
                reset,
                dragOver,
            })

            activate()

            scope.$on('schemaFormReset', (event, newImageUrl) => {
                reset(newImageUrl)
            })
            scope.$on('schemaFormResetUploaders', (event, newImageUrl) => {
                reset(newImageUrl)
            })
            scope.$on('schemaFormValidate', scope.validateField)
            scope.$on('schemaFormFileUploadSubmit', fileUploadSubmit)

            function activate() {
                reset()

                if (scope.form.useSimpleForm) scope.dropboxes = defineDropBoxes().simple

                //image mimeType
                if (scope.form.schema.pattern.mimeType === scope.dropBoxes.imageUpload.mimeType) {
                    scope.imageUpload = true
                    scope.currentDropBox = scope.dropBoxes.initial = scope.dropBoxes.imageUpload
                    scope.dropBoxLabel = scope.currentDropBox.label
                }

                //set the changeButton text
                scope.changeButtonText = scope.currentDropBox.changeButtonText
                scope.changeButtonIcon = scope.currentDropBox.changeButtonIcon

                //check if file already uploaded & set the form label
                if (scope.currentImageUrl != undefined) {
                    scope.formLabel = $translate.instant('modules.upload.formLabelImage')
                    hideUploader()
                }
                else if (scope.currentFileUrl) {
                    scope.formLabel = $translate.instant('modules.upload.formLabelFile')
                    hideUploader()
                }
                else scope.canHideUploader = false

                if (scope.model) {
                    if (scope.model.preSelectedFile) {
                        selectFile(scope.model.preSelectedFile)
                        delete scope.model.preSelectedFile
                    }
                }
            }

            function selectFile(file) {
                if (scope.isValid === false) scope.isValid = true

                if (file != null) {
                    checkIfFileIsImage(file)
                    scope.picFile = file

                    const filename = _.get(file, 'name')
                    if (typeof filename === 'string' && scope.model) {
                        const splitOnDot = filename.split('.')
                        scope.model.title = splitOnDot.length > 1 && splitOnDot[splitOnDot.length - 1].length < 5 ? splitOnDot.slice(0, -1).join('.') : filename
                    }

                    if (scope.form.useSimpleForm) scope.canResetForm = true

                    hideDropBox()
                }
                else {
                    if (!scope.form.useSimpleForm) {
                        scope.currentDropBox = scope.dropBoxes.rejectUpload
                        ngFormElement.addClass('drop-denied')
                        $timeout(() => {
                            scope.currentDropBox = scope.dropBoxes.initial
                            ngFormElement.removeClass('drop-denied')
                        }, 7000)
                    }
                }

            }

            function selectFiles(files) {
                if (scope.isValid === false) scope.isValid = true

                scope.picFiles = files
            }

            function showUploader() {
                scope.uploaderVisible = true
            }

            function hideUploader() {
                scope.reset()
                scope.uploaderVisible = false
            }

            function uploadFile(file) {
                if (file) return doUpload(file)
            }

            function uploadFiles(files) {
                files.length && angular.forEach(files, (file) => {
                    doUpload(file)
                })
            }

            function validateField($event, formName, validator) {
                //    if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
                //       console.log('singlefile-form is invalid');
                //    } else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
                //       console.log('multifile-form is  invalid');
                //    } else {
                //       console.log('single- and multifile-form are valid');
                //    }
                if (uploadType === 'file' && !(scope.currentFileUrl || (scope.picFile && scope.uploadForm.file && scope.uploadForm.file.$viewValue))) {
                    scope.form.isValid = false
                    scope.isValid = false
                    if (scope.uploadForm.file) scope.uploadForm.file.$valid = false

                    if (validator) validator.isValid = false
                }
                else {
                    scope.form.isValid = true
                    scope.isValid = true

                    if (scope.uploadForm.file) scope.uploadForm.file.$valid = true
                }
            }

            function submit() {
                if (scope.uploadForm.file && scope.uploadForm.file.$valid && scope.picFile && !scope.picFile.$error) {
                    return scope.uploadFile(scope.picFile)
                }
                else if (scope.uploadForm.files && scope.uploadForm.files.$valid && scope.picFiles && !scope.picFiles.$error) {
                    return scope.uploadFiles(scope.picFiles)
                }
            }

            function reset(newImageUrl) {
                scope.picFile = null
                scope.picFiles = null
                scope.canResetForm = false
                if (newImageUrl) scope.currentImageUrl = newImageUrl

                if (scope.currentImageUrl && scope.currentImageUrl.length) {
                    scope.uploaderVisible = false
                    scope.canHideUploader = true
                }
                else {
                    scope.uploaderVisible = true
                    scope.canHideUploader = false
                }
                scope.isValid = undefined

                showDropBox()
            }

            function doUpload(file) {
                const df = $.Deferred()

                scope.headers = {
                    Authorization: wfAuth.getAuthenticationHeaderValue(),
                }

                if (file && !file.$error && scope.url) {
                    file.upload = Upload.upload({
                        url: scope.url,
                        file,
                        headers: scope.headers,
                    })

                    file.upload.then((response) => {
                        $timeout(() => {
                            file.result = response.data
                        })
                        ngModel.$setViewValue(response.data)
                        ngModel.$commitViewValue()
                        df.resolve(response)
                    }, (response) => {
                        if (response.status > 0) {
                            scope.errorMsg = response.status + ': ' + response.data
                        }
                        df.reject(response)
                    })

                    file.upload.progress((evt) => {
                        file.progress = Math.min(100, parseInt(100.0 *
								evt.loaded / evt.total))
                    })
                }
                return df.promise()
            }

            function fileUploadSubmit(event, deferred) {
                const submitResult = scope.submit()
                if (submitResult) {
                    submitResult.then((response) => {
                        if (scope.picFile && scope.picFile.blobUrl) {
                            if (uploadType === 'image') scope.currentImageUrl = scope.picFile.blobUrl

                            reset()
                        }
                        deferred.resolve(response)
                    }, (response) => {
                        deferred.reject(response)
                    })
                }
                else {
                    deferred.resolve()
                }
            }

            function defineDropBoxes() {
                return {
                    default: {
                        initial: {
                            singlefileUpload: scope.isSinglefileUpload,
                            label: $translate.instant('modules.upload.dropBoxes.initial.label'),
                            icon: 'fas fa-file-upload',
                            headerText: $translate.instant('modules.upload.dropBoxes.initial.headerText'),
                            descriptionText: $translate.instant('modules.upload.dropBoxes.initial.descriptionText'),
                            browseButtonText: $translate.instant('modules.upload.dropBoxes.initial.browseButtonText'),
                            changeButtonIcon: 'fas fa-sync-alt',
                            changeButtonText: $translate.instant('modules.upload.dropBoxes.initial.changeButtonText'),
                            color: '#bdc3c7',
                            mimeType: getValidFileTypesString(),
                        },
                        imageUpload: {
                            label: $translate.instant('modules.upload.dropBoxes.imageUpload.label'),
                            singlefileUpload: scope.isSinglefileUpload,
                            icon: 'fas fa-image',
                            headerText: $translate.instant('modules.upload.dropBoxes.imageUpload.headerText'),
                            descriptionText: $translate.instant('Or').toLowerCase(),
                            browseButtonText: $translate.instant('modules.upload.dropBoxes.imageUpload.browseButtonText'),
                            changeButtonIcon: 'fa fa-upload',
                            changeButtonText: $translate.instant('modules.upload.dropBoxes.imageUpload.changeButtonText'),
                            color: '#bdc3c7',
                            mimeType: 'image/*',
                        },
                        acceptUpload: {
                            label: $translate.instant('modules.upload.dropBoxes.acceptUpload.label'),
                            icon: 'fas fa-arrow-down',
                            headerText: $translate.instant('modules.upload.dropBoxes.acceptUpload.headerText'),
                            descriptionText: $translate.instant('modules.upload.dropBoxes.acceptUpload.descriptionText'),
                            browseButtonText: $translate.instant('modules.upload.dropBoxes.acceptUpload.browseButtonText'),
                            color: '#3498db',
                        },
                        rejectUpload: {
                            label: $translate.instant('modules.upload.dropBoxes.rejectUpload.label'),
                            icon: 'fa fa-ban',
                            headerText: $translate.instant('modules.upload.dropBoxes.rejectUpload.headerText'),
                            descriptionText: $translate.instant('modules.upload.dropBoxes.rejectUpload.descriptionText'),
                            browseButtonText: $translate.instant('modules.upload.dropBoxes.rejectUpload.browseButtonText'),
                            color: '#e74c3c',
                        },
                    },
                    simple: {
                        default: {
                            initial: {
                                singlefileUpload: scope.isSinglefileUpload,
                                label: $translate.instant('modules.upload.dropBoxes.initial.label'),
                                icon: 'fas fa-cloud-upload-alt',
                                color: '#bdc3c7',
                                mimeType: getValidFileTypesString(),
                            },
                            imageUpload: {
                                label: $translate.instant('modules.upload.dropBoxes.imageUpload.label'),
                                singlefileUpload: scope.isSinglefileUpload,
                                icon: 'fas fa-image',
                                color: '#bdc3c7',
                                mimeType: 'image/*',
                            },
                            acceptUpload: {
                                label: $translate.instant('modules.upload.dropBoxes.acceptUpload.label'),
                                icon: 'fas fa-image',
                                headerText: $translate.instant('modules.upload.dropBoxes.acceptUpload.headerText'),
                                descriptionText: $translate.instant('modules.upload.dropBoxes.acceptUpload.descriptionText'),
                                browseButtonText: $translate.instant('modules.upload.dropBoxes.acceptUpload.browseButtonText'),
                                color: '#3498db',
                            },
                            rejectUpload: {
                                label: $translate.instant('modules.upload.dropBoxes.rejectUpload.label'),
                                icon: 'fa fa-ban',
                                headerText: $translate.instant('modules.upload.dropBoxes.rejectUpload.headerText'),
                                descriptionText: $translate.instant('modules.upload.dropBoxes.rejectUpload.descriptionText'),
                                browseButtonText: $translate.instant('modules.upload.dropBoxes.rejectUpload.browseButtonText'),
                                color: '#e74c3c',
                            },
                        },
                    },
                }
            }

            function showDropBox() {
                scope.currentDropBox = scope.dropBoxes.initial
                scope.dropBoxLabel = scope.currentDropBox.label

                scope.showDropBox = true

                if (scope.currentFileUrl != undefined || scope.currentImageUrl != undefined) scope.canHideUploader = true

                $timeout()
            }

            function hideDropBox() {
                scope.showDropBox = false
            }

            function dragOver(isDragging, dragClass) {
                if (isDragging && dragClass == 'drag-enter allow-dropping') scope.currentDropBox = scope.dropBoxes.acceptUpload
                else if (isDragging && dragClass == 'drop-denied') scope.currentDropBox = scope.dropBoxes.rejectUpload
                else scope.currentDropBox = scope.dropBoxes.initial

                $timeout()
            }

            function checkIfFileIsImage(file) {
                scope.imageUpload = _.includes(file.type, 'image')

                if (!($.browser.msie || $.browser.safari)) // tiff is supported only in IE (not Edge) & Safari
                    scope.unsupportedFileTypes = _.includes(file.type, 'tiff')
            }
        }
    }
})()
